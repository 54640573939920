import React, { useState } from "react";
import Slider from "react-slick";
import { Image, Box } from "@chakra-ui/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const DraggableImageSlider = ({ images }) => {
  const [enlargedIndex, setEnlargedIndex] = useState(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    draggable: true, 
    swipe: true, 
    centerMode: true,
    centerPadding: "40px", 
  };

  const handleImageClick = (index) => {
    setEnlargedIndex(index === enlargedIndex ? null : index);
  };

  return (
    <Box width="80%" mx="auto">
      <Slider {...settings}>
        {images.map((item, index) => (
          <div key={index} style={{ borderRadius: "18px" }}>
            <div
              style={{
                height: enlargedIndex === index ? "200px" : "120px",
                width: "100%",
                background: "transparent",
                transition: "height 0.3s ease-in-out",
                cursor: "pointer",
              }}
              onClick={() => handleImageClick(index)}
            >
              <Image
                src={item.url}
                alt={`Image ${index}`}
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                  border: enlargedIndex === index ? "3px solid #001" : "none",
                  filter: enlargedIndex === index ? "blur(5px)" : "none",
                }}
              />
            </div>
          </div>
        ))}
      </Slider>

      {enlargedIndex !== null && (
        <Box
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          background="rgba(0, 0, 0, 0.6)"
          zIndex={999}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={() => handleImageClick(null)}
        >
          <Image
            src={images[enlargedIndex].url}
            alt={`Image ${enlargedIndex}`}
            style={{
              maxHeight: "80vh",
              maxWidth: "80%",
              borderRadius: "20px",
              objectFit: "cover",
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default DraggableImageSlider;
