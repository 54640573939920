import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import { Box, Text, Flex, Image } from "@chakra-ui/react";
import { TextField } from "@mui/material";
import { useDropzone } from "react-dropzone"; // Import the useDropzone hook
// import "../../ProfileScreen.css";
import '../../Styles/ProfileScreen.css'
import Button from "components/General/Button";

// REDUX
import {
  userUpdateRequest,
  userUpdateSuccess,
  userUpdateFail,
} from "../../state/index";

// Components
import UserSideWidgetTwo from "scenes/widgets/UserWidgets/UserSideWidgetTwo";
import Dropzone from "react-dropzone";
import FeaturedWorkUpload from "./FeaturedWorkUpload";

// Validation
const validationSchema = yup.object().shape({
  username: yup.string().required("Required"),
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  email: yup.string().email("Invalid email").required("Required"),
  password: yup.string(),
  bio: yup.string(),
  bookingLink: yup.string(),
  employedAt: yup.string(),
  location: yup.string(),
  occupation: yup.string(),
  picturePath: yup.string(),
});

const EditProfile = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  // State
  const [selectedImage, setSelectedImage] = useState("./404.png");

  const handleSubmit = async (values, { resetForm }) => {
    try {
      dispatch(userUpdateRequest()); // start loading state

      const response = await fetch(
        `http://localhost:8000/users/${user._id}/profile/edit`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(values),
        }
      );

      if (response.ok) {
        const updatedUser = await response.json();
        console.log('updated user', updatedUser)
        dispatch(userUpdateSuccess(updatedUser));
        resetForm();

        window.location.reload();
      } else {
        dispatch(userUpdateFail("Profile Update Failed."));
      }
    } catch (error) {
      dispatch(userUpdateFail("Error updating profile:" + error.message));
    }
  };

  const onDrop = (acceptedFiles) => {
    setSelectedImage(URL.createObjectURL(acceptedFiles[0]));
    console.log(acceptedFiles);
  };

  // useDropzone hook setup
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>

      <Box height="100vh" bgColor="#E8E4D8" padding="20px">
        <Box bgColor="white" borderRadius="20px" display="flex" flexDirection='column'>
          <Box flex={1}>
            <UserSideWidgetTwo
              userId={user._id}
              picturePath={user.picturePath}
            />
          </Box>

          {/* Form Wrapper */}
          {/* RIGHT */}
          <Box flex={3} marginTop={["50px"]} padding={["0px", "20px"]}>
            <Box>
              <Text as="h1" fontSize={["19px"]}>
                Edit Profile
              </Text>
            </Box>

            <hr />

            <Formik
              initialValues={{
                username: user.username,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                password: user.password,
                bio: user.bio,
                picturePath: user.picturePath,
                bgPicturePath: user.bgPicturePath,
                featuredWork: user.featuredWork,
                tattooStyles: user.tattooStyles,
                occupation: user.occupation,
                employedAt: user.employedAt,
                location: user.location,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                resetForm,
                touched,
              }) => (
                <form
                  onSubmit={handleSubmit}
                  style={{ marginTop: "30px", padding: "20px" }}
                >
                  <Text as="h1" fontSize={["23px"]} marginBottom="10px">
                    Personal Details
                  </Text>
                  <hr />
                  <Box
                    display="grid"
                    gridGap={3}
                    gridTemplateColumns={["1fr", "1fr 1fr"]}
                    justifyContent="center"
                  >
                    <TextField
                      label="First Name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstName || ""}
                      name="firstName"
                      sx={{ maxWidth: "300px", margin: "10px" }}
                      className="text_input"
                      placeholder={user.firstName}
                    />
                    <TextField
                      label="Last Name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lastName || ""}
                      name="lastName"
                      sx={{ maxWidth: "300px", margin: "10px" }}
                      className="text_input"
                      placeholder={user.lastName}
                    />
                    <TextField
                      label="Username"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.username || ""}
                      name="username"
                      sx={{ maxWidth: "300px", margin: "10px" }}
                      className="text_input"
                      placeholder={user.username}
                    />
                    <TextField
                      label="Email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email || ""}
                      name="email"
                      sx={{ maxWidth: "300px", margin: "10px" }}
                      className="text_input"
                      placeholder={user.email}
                    />
                    <TextField
                      label="Location"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.location || ""}
                      name="location"
                      sx={{ maxWidth: "300px", margin: "10px" }}
                      className="text_input"
                      placeholder={user.location}
                    />

                    {/* Add more fields here */}
                  </Box>

                  {/* PROFESSIONAL DETAILS */}
                  <Box
                    justifyContent="center"
                    alignItems="center"
                    marginTop="50px"
                  >
                    <Box>
                      <Text as="h1" fontSize={["24px"]}>
                        Professional Details
                      </Text>
                    </Box>

                    <Box>
                      <TextField
                        label="Occupation"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.location || ""}
                        name="occupation"
                        sx={{ maxWidth: "300px", margin: "10px" }}
                        className="text_input"
                        placeholder={user.occupation}
                      />
                      <TextField
                        label="Employed At"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.location || ""}
                        name="employedAt"
                        sx={{ maxWidth: "300px", margin: "10px" }}
                        className="text_input"
                        placeholder={user.employedAt}
                      />

                      {/* PROFILE PICTURE */}
                      <Dropzone
                        acceptedFiles=".jpg,.jpeg,.png"
                        multiple={false}
                        onDrop={(acceptedFiles) => {
                          setFieldValue("picture", acceptedFiles[0]);
                          setSelectedImage(
                            URL.createObjectURL(acceptedFiles[0])
                          );
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <Box
                            // display="flex"
                            alignItems="center"
                            justifyContent="center"
                            {...getRootProps()}
                            border={`1px solid gray`}
                            borderRadius="10px"
                            p="1rem"
                            sx={{ "&:hover": { cursor: "pointer" } }}
                          >
                            <input {...getInputProps()} />
                            {selectedImage ? (
                              <Flex
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Image
                                  src={selectedImage}
                                  alt="User Avatar"
                                  width={["15%", "15%", "20%"]}
                                  height={["15%", "15%", "20%"]}
                                  style={{ objectFit: "cover" }}
                                />
                                <Text
                                  as="h3"
                                  marginTop="2rem"
                                  fontSize={["14px"]}
                                >
                                  {values.picture && values.picture.name}
                                </Text>
                              </Flex>
                            ) : (
                              <Flex
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Image
                                  src={selectedImage}
                                  width={["15%", "15%", "20%"]}
                                  style={{ objectFit: "cover" }}
                                />
                                <Text as="h3">Add your own image</Text>
                              </Flex>
                            )}
                          </Box>
                        )}
                      </Dropzone>
                    </Box>

                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                      onClick={handleSubmit}
                      sx={{
                        marginTop: "50px",
                        color: "white",
                        background: "#2C2E37",
                      }}
                      text="Edit Profile"
                    />
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default EditProfile;
